import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import HeaderNav from '../HeaderFotter/Header/Header.js';
import Fotter from '../HeaderFotter/Fotter/Fotter.js';
import BlurryCursor from '../Dependence/Cursor/cursor.jsx';
import FixedNav from '../HeaderFotter/FixedNav/Fixednav.js';

const sizes = {
    desktop: 1024,
    tablet: 768,
    phone: 576,
};

const media = Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
    return acc;
}, {});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5;
  font-size: 20px;
  font-weight: 300;
  color: #111;
`;

const Section = styled.section`
  padding: 0 50px;
  padding-bottom: 150px;
  max-width: 1330px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /* Đảm bảo padding không thay đổi kích thước của div */
  box-sizing: border-box; 
  
  ${media.desktop`
    padding: 50px 30px; // ví dụ thay đổi padding trên desktop nhỏ
  `}
  ${media.tablet`
    padding: 20px 20px; // thay đổi padding trên tablet
  `}
  ${media.phone`
    padding: 10px 10px; // thay đổi padding trên điện thoại
    padding-bottom: 100px; // giảm padding bottom trên điện thoại
  `}
`;

const JdDevelop = () => {
    const [isActive, setIsActive] = useState(false);
    
    return (
        <Container className='content'>

            {/* headder */}
            <HeaderNav/>

            {/* Section 1 */}
            <Section className="about-vis opacity-1" >
                <div className=''>
                    <div className='flex justify-center contacts'>
                        <div>
                            <h1 onMouseOver={() => { setIsActive(true) }} onMouseLeave={() => { setIsActive(false) }} id="headline-4-9" className="uppercase text-clamp-100 m-0 ct-headline ">SOFTWARE DEVELOPER</h1>
                            <div className='flex justify-center text-clamp-50'>(Fresher accepted)</div>
                        </div>

                    </div>
                    <div className='flex justify-center uppercase blend fade z-10 leading-10 tracking-widest text-[33px]'>Join Our Journey of Innovation and Excellence</div>
                    <div>
                        <div id="thanh_den" style={{ width: "100%" }}></div>
                    </div>

                    <div className='flex justify-between gap-5'>
                        <div className='h-auto pl-[12%]'>
                            {/* <div id="thanh_trang" style={{ width: "2px", minWidth: "2px", height: "100%", margin: 0 }}></div> */}
                        </div>
                        <div className='w-full'>
                            <p><span className='pl-5'></span>Chúng tôi đang tìm kiếm các bạn Software Developer để tham gia vào VIS GROUP của
                                chúng tôi. Với vị trí là Software Developer, bạn sẽ chịu trách nhiệm thiết kế, phát triển và
                                triển khai các giải pháp phần mềm toàn diện. Cùng hợp tác chặt chẽ với các vị trí liên
                                quan khác, bạn sẽ góp phần tạo ra các ứng dụng và nền tảng web sáng tạo mang lại trải
                                nghiệm đặc biệt cho người dùng. Hình dung Ứng viên lý tưởng <strong>không phụ thuộc vào
                                    kinh nghiệm</strong> mà sẽ là các bạn Ứng viên có kiến thức vững chắc về các ngôn ngữ lập
                                trình và khuôn khổ khác nhau, cũng như hiểu biết sâu sắc về các kỹ thuật phát triển web
                                hiện đại và các phương pháp hay nhất.</p>
                            <p className='text-justify'><strong> 1. Mô tả công việc: <br /></strong>
                                <div className='pl-10'>

                                    Thiết kế, phát triển và triển khai các giải pháp phần mềm.<br />
                                    Làm việc cùng team để hiểu rõ yêu cầu về sản phẩm.<br />
                                    Khắc phục sự cố và cung cấp giải pháp kịp thời.<br />
                                    Cung cấp hướng dẫn kỹ thuật và hỗ trợ cho các nhà phát triển cơ sở khi cần thiết.<br />
                                </div>

                            </p>
                            <p className='text-justify'><strong>2. Yêu cầu cơ bản:<br /></strong>
                                <div className='pl-10'>

                                    Bằng cử nhân Khoa học máy tính, Kỹ thuật phần mềm hoặc Bằng cấp của các
                                    lĩnh vực liên quan (hoặc có khả năng tự học và nắm bắt công nghệ mới một
                                    cách nhanh chóng.).<br />
                                    Hiểu biết vững chắc về các dịch vụ web (REST, SOAP) và API.<br />
                                    Từng có kinh nghiệm sử dụng AWS hoặc Azure.<br />
                                    Hiểu và sử dụng thành thạo 1 trong các framework FE hiện tại : React/ Vue/
                                    Angular.<br />
                                    Có kiến thức về BE là 1 lợi thế : NodeJs/C#/Java/Python.<br />
                                    Từng làm việc với cơ sở dữ liệu NoSQL (Mongo) hoặc SQL(MySQL/SQL
                                    Server), máy chủ web (ví dụ: Apache).<br />
                                    Có tư duy lập trình và kỹ năng giải quyết vấn đề.<br />
                                    Trình độ tiếng Anh khá (đủ đọc hiểu các thông tin cho công việc).<br />
                                    Sở hữu tinh thần làm việc của startup: chủ động, chịu khó học hỏi, tự chịu trách
                                    nhiệm trong công việc, có mindset của người-giải-quyết-vấn-đề.<br />
                                    Có kỹ năng làm việc nhóm tốt, hỗ trợ đồng nghiệp cùng/khác team và giao tiếp
                                    hiệu quả.<br />
                                    Có hiểu biết về lĩnh vực Thương mại điện tử.<br />
                                </div>
                            </p>
                            <p className='text-justify'><strong>3. Quyền lợi: <br /></strong>
                                <div className='pl-10'>
                                    Được cấp máy tính để làm việc.<br />
                                    Thử việc 2 tháng, 85% lương chính thức.<br />
                                    Lương tháng 13, review performance 2 lần/ năm.<br />
                                    12 ngày phép năm; nghỉ lễ Tết theo quy định Nhà nước.<br />
                                    Du lịch trong & ngoài nước hằng năm, tham gia vào các hoạt động thú vị tại
                                    công ty: Monthly Birthday, Team Building, Christmas, Year-End Party,,...<br />
                                    Môi trường làm việc thân thiện, thẳng thắn, công bằng, năng động, sáng tạo
                                    không ngừng.<br />
                                    Thoải mái đưa ra những ý tưởng, đề xuất của bản thân vì chúng tôi luôn tin vào
                                    năng lực của mỗi cá nhân và làm việc với văn hóa “Advance on Trust”.<br />
                                </div>
                            </p>
                            <p>🙌 Hãy ứng tuyển ngay để bắt đầu một hành trình sự nghiệp thú vị với đại gia đình VIS chúng tôi !</p>
                        </div>
                    </div>

                    <div>
                        <div className='mt-48' id="thanh_trang" style={{ minHeight: "1px", height: "1px", width: "100%" }}></div>
                    </div>

                    <div className='flex justify-between gap-5'>
                        {/* <div className='h-auto pl-20'>
                            <div id="thanh_den" style={{ width: "2px", minWidth: "2px", height: "100%", margin: 0 }}></div>
                        </div> */}
                        <div className='w-full '>
                            <p className='text-justify'><strong>Thông tin liên hệ VIS GROUP:<br /></strong>
                                <div className='pl-10'>
                                    - Địa chỉ làm việc: 15 Nguyễn Mộng Tuân, Liên Chiểu, Đà Nẵng<br />
                                    - Thời gian làm việc:<br />
                                    <div className='pl-10'>
                                        + Full-time làm việc các ngày từ thứ 2 – thứ 7 (thứ 7 làm cách tuần).<br />
                                        + Sáng: 8h00 – 12h00 Chiều: 13h30 – 17h30<br />
                                    </div>
                                    <p></p>
                                    Ứng viên vui lòng gửi CV về địa chỉ email: recruitment@visgroup.co<br />
                                    Email title <span className='text-red-400'>[SOFTWARE DEVELOPER – HỌ TÊN – SĐT]</span>
                                </div>

                            </p>
                        </div>
                    </div>
                </div>
            </Section>

            <Fotter></Fotter>

            <FixedNav />
            <BlurryCursor isActive={isActive} />
        </Container>
    );
};

export default JdDevelop;