import React, { useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const sizes = {
  desktop: 1024,
  tablet: 768,
  phone: 576,
};

const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5;
  font-size: 20px;
  font-weight: 300;
  color: #111;
`;

const Section = styled.section`
  padding: 0 50px;
  padding-bottom: 10px;
  max-width: 1330px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /* Đảm bảo padding không thay đổi kích thước của div */
  box-sizing: border-box;

  ${media.desktop`
    padding: 50px 30px; // ví dụ thay đổi padding trên desktop nhỏ
  `}
  ${media.tablet`
    padding: 20px 20px; // thay đổi padding trên tablet
  `}
  ${media.phone`
    padding: 10px 10px; // thay đổi padding trên điện thoại
    padding-bottom: 100px; // giảm padding bottom trên điện thoại
  `}
`;

const HeaderNav = () => {
  gsap.registerPlugin(useGSAP);
  const content = useRef();

  useGSAP(
    () => {
      gsap.from(".effect1", {
        opacity: 0,
        x: -200,
        duration: 3,
        ease: "power3.out",
      });

      gsap.from(".effect2", {
        opacity: 0,
        y: -200,
        duration: 3,
        ease: "power3.out",
      });

      gsap.from(".effect3", {
        opacity: 0,
        width: "1%",
        duration: 3,
        ease: "power3.out",
      });
    },
    { scope: content }
  );

  return (
    <Container className="content" ref={content}>
      <Section
        className="head-title sm:flex justify-between text-[25px]"
        style={{ paddingBottom: "80px" }}
      >
        <div className="logo effect1 flex justify-center">
          <a href="/">
            <img src="/Img/logo.png" alt="Logo" className="h-[120px] w-auto" />
          </a>
        </div>
        <div className="effect2 head-nav flex items-center justify-around md:gap-5 tracking-[1px] text-[25px] font-bold">
          <a href="/abouts" className="no-underline text-[#000]">
            About Us
          </a>
          <a href="/recruitments" className="no-underline text-[#000]">
            Recruitments
          </a>
          {/* <a href="/">PRODUCTS</a> */}
          <a href="/contacts" className="no-underline text-[#000]">
            Contact
          </a>
        </div>
        <div className="w-1/12 hidden md:flex justify-end items-center roundded-2xl text-[15px]">
          <select className="w-8/12">
            <option>Eng</option>
          </select>
        </div>
      </Section>
    </Container>
  );
};

export default HeaderNav;
