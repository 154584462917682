'use client';
import React, { useEffect, useRef } from 'react'
import gsap from 'gsap';

export default function BlurryCursor({isActive}) {
    const size = isActive ? 300 : 20;
    const mouse = useRef({ x: 0, y: 0 });
    const circle = useRef();
    const delayedMouse = useRef({
        x: 0,
        y: 0
    });

    // cái này cho độ trễ di chuyển chuột
    const lerp = (x, y, a) => x * (1 - a) + y * a;

    const manageMouseMove = (e) => {
        const { clientX, clientY } = e;

        mouse.current = {
            x: clientX,
            y: clientY
        };

        moveCircle(mouse.current.x, mouse.current.y);
    };

    const animate = () => {
        const { x, y } = delayedMouse.current;
        delayedMouse.current = {
            x: lerp(x, mouse.current.x, 0.02),
            y: lerp(y, mouse.current.y, 0.02)
        };

        if (circle.current) {  // Chỉ thực hiện khi circle.current không null
            moveCircle(delayedMouse.current.x, delayedMouse.current.y);
        }
        window.requestAnimationFrame(animate);
    };

    const moveCircle = (x, y) => {
        if (circle.current) {  // Kiểm tra nếu circle.current không null
            gsap.set(circle.current, { x, y, xPercent: -50, yPercent: -50 });
        }
    };

    useEffect(() => {
        window.addEventListener("mousemove", manageMouseMove);
        animate();  // Khởi động animation sau khi đã thêm event listener
        return () => {
            window.removeEventListener("mousemove", manageMouseMove);
        };
    }, []);

    return (
        <div
            ref={circle}
            style={{
                backgroundColor: "#fff",
                width: size,
                height: size,
                filter: `blur(${isActive ? 30 : 0}px)`,
                transition: `height 0.3s ease-out, width 0.3s ease-out, filter 0.3s ease-out`
            }}
            className='top-0 left-0 fixed rounded-full mix-blend-difference pointer-events-none md:block hidden'
        />
    );
}
