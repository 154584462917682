import React, { useRef, useState, useEffect } from "react";
import styled, { css } from "styled-components";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useForm } from "react-hook-form";

import HeaderNav from "../../HeaderFotter/Header/Header.js";
import Fotter from "../../HeaderFotter/Fotter/Fotter.js";
import BlurryCursor from "../../Dependence/Cursor/cursor.jsx";
import FixedNav from "../../HeaderFotter/FixedNav/Fixednav.js";
import { useNavigate } from 'react-router-dom';
import axiosInstance from "../../../axiosInstance";
import { ColorRing } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const sizes = {
  desktop: 1024,
  tablet: 768,
  phone: 576,
};

const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5;
  font-size: 20px;
  font-weight: 300;
  color: #111;
`;

const Section = styled.section`
  padding: 0 50px;
  padding-bottom: 150px;
  max-width: 1330px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /* Đảm bảo padding không thay đổi kích thước của div */
  box-sizing: border-box;

  ${media.desktop`
    padding: 50px 30px; // ví dụ thay đổi padding trên desktop nhỏ
  `}
  ${media.tablet`
    padding: 20px 20px; // thay đổi padding trên tablet
  `}
  ${media.phone`
    padding: 10px 10px; // thay đổi padding trên điện thoại
    padding-bottom: 100px; // giảm padding bottom trên điện thoại
  `}
`;

const Challenge = ({ setToken }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  // const [token, setToken] = useState('');

  const navigate = useNavigate();
  gsap.registerPlugin(useGSAP);
  const content = useRef();
  gsap.registerPlugin(ScrollTrigger);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    data["username"] = "admin"
    setIsLoading(true);

    try {
      const response = await axiosInstance.post("/auth/login", data);
      if (response.status === 200) {
        // Thoong baso thanh cong o day:
        toast.success("Login successfully!");
        const token = response.data.token;
        localStorage.setItem('token', token);
        setToken(token);

        setTimeout(() => {
          navigate("/challenge");
        }, 500)

      } else {
        toast.error("Login failed. Please try again.");
      }
    } catch (error) {
      console.error("Error login:", error);
      toast.error("Login failed. Please try again.");
    } finally {
      setIsLoading(false); // Kết thúc loading
    }
  };

  useEffect(() => {
    // Chắc chắn rằng window và document đều sẵn sàng
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      ScrollTrigger.refresh();
    }
  }, []);

  useEffect(() => {
    const imagesLoaded = document.querySelectorAll("img");
    const totalImages = imagesLoaded.length;
    let imagesLoadedCount = 0;

    imagesLoaded.forEach((image) => {
      if (image.complete) {
        imagesLoadedCount++;
      } else {
        image.onload = () => {
          imagesLoadedCount++;
          if (imagesLoadedCount === totalImages) {
            // Khởi tạo ScrollTrigger sau khi tất cả hình ảnh đã tải xong
            ScrollTrigger.refresh();
          }
        };
      }
    });

    if (imagesLoadedCount === totalImages) {
      ScrollTrigger.refresh();
    }
  }, []);

  return (
    <Container className="content" ref={content}>
      {/* headder */}
      <HeaderNav></HeaderNav>

      {/* Section 1 */}
      <Section
        className="our-teams opacity-1 bg-mask"
        style={{ paddingTop: "100px" }}
      >
        <div className="ct-main">
          <div className="ct-div-black"></div>
          <div className="ct-div-block">
            <div className="w-full">
              <div
                className="flex flex-col items-center max-w-[300px] m-auto p-10 rounded-xl bg-[#f9f9f9]"
                style={{
                  boxShadow:
                    "-3px 0px 20px 8px rgba(0, 0, 0, .75), -6px 12px 20px 0px rgba(0, 0, 0, .6), -13px 14px 8px 0px rgba(0, 0, 0, .6), -40px 40px 20px 0 rgba(0, 0, 0, .35)",
                }}
              >
                <h2 className="font-bold pb-10">Login</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div
                    className="flex w-full"
                    style={{ boxSizing: "border-box" }}
                  >
                    <label className="pr-5" htmlFor="email">
                      User:{" "}
                    </label>
                    <span>Admin</span>
                  </div>

                  <div className="flex w-full ">
                    {/* <label className="pr-5" htmlFor="password">
                      Password:{" "}
                    </label> */}
                    <input
                      className=" border-b-4 border-indigo-500 p-2"
                      id="password"
                      type="password"
                      placeholder="Password"
                      {...register("password", {
                        required: "Password is required",
                      })}
                    />
                  </div>
                  {errors.password && (
                    <div className="error text-red-500">
                      {errors.password.message}
                    </div>
                  )}
                  <div className="mt-10 flex justify-center">
                    <button type="submit" className="hover:bg-blue-400 font-bold">Login</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Section>

      {/* Section 7 */}
      <Section className="info-overview">
        <div>
          <div> Phone: 0973759907 </div>
          <div>
            {" "}
            Address: 15 Nguyen Mong Tuan, Hoa Minh, Lien Chieu, Da Nang{" "}
          </div>
          <div>
            {" "}
            Page:
            <a
              className="no-underline"
              href="https://www.facebook.com/visstudio.co"
            >
              {" "}
              VIS Group
            </a>{" "}
          </div>
          <br />
          <div id="ct-main-icon">
            <img
              alt=""
              src="/Img/icon/amazon.png"
              className="ct-image fade entered lazyloaded"
              style={{ transform: "translate(0px, 0px)", opacity: "1" }}
            />
            <img
              alt=""
              src="/Img/icon/tiktok.png"
              className="ct-image fade entered lazyloaded"
              style={{ transform: "translate(0px, 0px)", opacity: "1" }}
            />
            <img
              alt=""
              src="/Img/icon/etsy.jfif"
              className="ct-image fade entered lazyloaded"
              style={{ transform: "translate(0px, 0px)", opacity: "1" }}
            />
            <img
              alt=""
              src="https://www.pinguinweb.de/wp-content/uploads/2022/09/woocommerce.png"
              className="ct-image fade entered lazyloaded"
              style={{ transform: "translate(0px, 0px)", opacity: "1" }}
            />
            <img
              alt=""
              src="https://www.pinguinweb.de/wp-content/uploads/2022/09/oxy.png"
              className="ct-image fade entered lazyloaded"
              style={{ transform: "translate(0px, 0px)", opacity: "1" }}
            />
          </div>
        </div>
      </Section>

      <Fotter></Fotter>

      <FixedNav />
      <BlurryCursor isActive={isActive} />

      <ToastContainer />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgb(75 81 99 / 80%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <ColorRing
            height={70}
            width={70}
            color="#4fa94d"
            ariaLabel="loading-indicator"
          />
        </div>
      )}
    </Container>
  );
};

export default Challenge;
