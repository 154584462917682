import gsap from 'gsap';

export const effect_gsap = (param_clas, y, mobile) => {
    return gsap.fromTo(param_clas, {
        opacity: 0,
        y: -100
    }, {
        opacity: 1,
        y,
        ease: "power1.inOut",
        scrollTrigger: {
            trigger: param_clas,
            start: mobile ? "top center" : "top 85%",
            end: mobile ? "1% center" : "1% 85%",
            toggleActions: "play none none reverse",
            scrub: 4,
            duration: 2,
            // markers: mobile ? true : false
        }
    });
};

export const effect_out = (param_clas, y, mobile) => {
    return gsap.fromTo(param_clas, {
        y: 0
    }, {
        opacity: 0,
        y,
        ease: "power1.inOut",
        scrollTrigger: {
            trigger: param_clas,
            start: mobile? "bottom 40%" :"90% center",
            end: mobile? "bottom 40%" : "bottom center",
            toggleActions: "complete reverse reverse complete",
            scrub: 4,
            duration: 2,
            // markers: mobile ? true : false
            // markers: true
        }
    });
};


// Cái này ở  Establish
export const move_year = (param_class_move, param_ref,  y) => {
    return gsap.fromTo(param_class_move, { opacity: 0, y }, {
        opacity: 1,
        y: 0,
        ease: "power2.out",
        scrollTrigger: {
          trigger: param_ref,
          start: "top 40%",
          end: "bottom top",
          toggleActions: "play none none reverse",
          scrub: 6,
          duration: 15,
        //   markers: true
        }
      })
}


export const effect_img = (param_clas, x=0, y=0) => {
    return gsap.fromTo(param_clas, { opacity: 0, x , y }, {
        opacity: 1,
        x: 0,
        y: 0,
        ease: "power3.out",
        scrollTrigger: {
            trigger: param_clas,
            start: "top center",
            end: "center center",
            toggleActions: "play none none reverse",
            scrub: 6,
            duration: 15,
            // markers: true
        }
    })
};