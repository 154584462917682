import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import "./Contact.css";
import axiosInstance from "../../../axiosInstance";
import { ColorRing } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 300;
  color: #111;
`;

const Section = styled.section`
  padding: 0 50px;
  padding-bottom: 150px;
  max-width: 1330px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /* Đảm bảo padding không thay đổi kích thước của div */
  box-sizing: border-box; 
`;

const Contact = () => {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const savedData = localStorage.getItem('backgroundData');
        if (savedData) {
            const formData = JSON.parse(savedData);
            for (const [key, value] of Object.entries(formData)) {
                setValue(key, value);
            }
        }
    }, [setValue]);

    const onSubmit = async (data) => {
        setIsLoading(true);

        try {
            const response = await axiosInstance.post('/send-mail', data)
            if (response.status === 200) {
                // Thoong baso thanh cong o day:
                toast.success('Form submitted successfully!');
            }
            else {
                toast.error('Form submission failed. Please try again.');
            }
        } catch (error) {
            console.error('Error uploading data:', error);
            toast.error('Form submission failed. Please try again.');

        } finally {
            setIsLoading(false); // Kết thúc loading
        }
    };
    return (
        <Container className='content'>
            <Section className="contact-sc" style={{ opacity: "1", filter: "none" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='contacts'>
                        <div className='flex justify-center contacts text-[120px] font-normal'>
                            <h2 className='text-[#6c6a6c] pb-20'>Ready for your partnership</h2>
                        </div>
                        <div>
                            {/* <div style={{ width: "100%;", display: "flex", "align-items": "flex-end", "flex-direction": "column" }}>
                                <img src='/Img/head-logo.png' alt='Logo' style={{ width: "20%" }} />
                            </div> */}
                            {/* <div className='w-full flex justify-center'>
                                <div id="thanh_den" style={{ marginRight: 0, width: "70%" }}></div>
                            </div> */}
                        </div>

                        <div className="form-container ">
                            <div className="form-contacts md:flex gap-20 ">
                                <div className="form-group ">
                                    <input type="text" placeholder='Firts Name * ' {...register("firts_name", { required: "Firts Name is require!" })} />
                                    {errors.firts_name && <div style={{ color: "red" }}>{errors.firts_name.message}</div>}
                                </div>
                                <div className="form-group">
                                    <input type="text" placeholder='Last Name * '{...register("lastName", { required: "Last Name is require!" })} />
                                    {errors.lastName && <div style={{ color: "red" }}>{errors.lastName.message}</div>}
                                </div>
                            </div>
                            <div className="form-contacts">
                                <div className="form-group">
                                    <input type="email" placeholder='Email * '{...register("Email", { required: "Email is require!" })} />
                                    {errors.Email && <div style={{ color: "red" }}>{errors.Email.message}</div>}
                                </div></div>

                            <div className="form-contacts">
                                <div className="form-group">
                                    <input type="text" placeholder='Tel * '{...register("Tel", { required: "Tel is require!" })} />
                                    {errors.Tel && <div style={{ color: "red" }}>{errors.Tel.message}</div>}
                                </div>
                            </div>
                            <div className="form-contacts">
                                <div className="form-group">
                                    <input type="text" placeholder='Message * '{...register("Message", { required: "Message is require!" })} />
                                    {errors.Message && <div style={{ color: "red" }}>{errors.Message.message}</div>}
                                </div>
                            </div>
                            <div className='text-[#68628d] px-10'>
                                {/* <p>Xin lưu ý rằng ngân sách tối thiểu cho một dự án là €3.500 ròng và thời gian chờ đợi trung bình cho đến khi dự án bắt đầu là khoảng 4 tuần. Vì chúng tôi xác định rất rõ các dự án của khách hàng và hỗ trợ khách hàng lâu dài nên khả năng tiếp nhận khách hàng mới của chúng tôi bị hạn chế. Vui lòng chỉ liên hệ với chúng tôi nếu bạn muốn hợp tác lâu dài .</p> */}
                                <p className='p-0'>We also hope you understand that we generally do not respond to promotional messages.</p>

                            </div>
                            <div>
                                <input type="checkbox" required="" {...register("Checkbox", { required: "Please agree to the terms and conditions before submitting the form!" })}></input>
                                <font >I agree that this website stores the information I submit so that it can respond to my request. <font style={{ color: "red" }}>*</font></font>
                                {errors.Checkbox && <div style={{ color: "red" }}>{errors.Checkbox.message}</div>}
                            </div>
                        </div>

                        <div className='flex justify-center pt-10'>
                            <button type="submit" className='px-12 py-4 text-xl rounded-xl hover:bg-[#45a049]'>Submit</button>
                        </div>

                    </div>
                </form>

                <ToastContainer />
                {isLoading && (
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgb(75 81 99 / 80%)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999
                    }}>
                        <ColorRing
                            height={70}
                            width={70}
                            color="#4fa94d"
                            ariaLabel="loading-indicator"
                        />
                    </div>
                )}
            </Section>
        </Container>
    );
};

export default Contact;