import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

const sizes = {
  desktop: 1024,
  tablet: 768,
  phone: 576,
};

const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5;
  font-size: 20px;
  font-weight: 300;
  color: #111;
`;

const Section = styled.section`
  padding: 0 50px;
  padding-bottom: 10px;
  max-width: 1330px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /* Đảm bảo padding không thay đổi kích thước của div */
  box-sizing: border-box;

  ${media.desktop`
    padding: 50px 30px; // ví dụ thay đổi padding trên desktop nhỏ
  `}
  ${media.tablet`
    padding: 20px 20px; // thay đổi padding trên tablet
  `}
  ${media.phone`
    padding: 10px 10px; // thay đổi padding trên điện thoại
    padding-bottom: 100px; // giảm padding bottom trên điện thoại
  `}
`;

const HeaderNavAdmin = ({ user }) => {
  const navigate = useNavigate();
  const [nav, setNav] = useState(false);
  const navRef = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // Nếu nav hiển thị và click ra ngoài element nav, thì ẩn nó đi
      if (nav && navRef.current && !navRef.current.contains(e.target)) {
        setNav(false);
      }
    };

    // Thêm event listener khi component mount
    document.addEventListener("mousedown", checkIfClickedOutside);

    // Dọn dẹp event listener khi component unmount
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [nav]); // Rerun nếu nav thay đổi

  const nav_profile = (event) => {
    event.preventDefault()

    setNav(true);
  };

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <Container className="content">
      <Section
        className="head-title sm:flex justify-between text-[25px]"
        style={{ paddingBottom: "80px" }}
      >
        <div className="logo effect1 flex justify-center">
          <a href="/">
            <img src="/Img/logo.png" alt="Logo" className="h-[120px] w-auto" />
          </a>
        </div>

        <div className="text-[20px] w-4/12 hidden md:flex justify-end items-center roundded-2xl text-[15px]">
          <a
            href="#"
            className="no-underline font-bold flex"
            onClick={nav_profile}
          >
            <div>
              <div className="text-gray-700"> {user.name}</div>
              <div className="text-gray-400">{user.username}</div>
            </div>
            <div className="pl-5 ">
              <img src="/avatar.jpg" className="w-[60px] rounded-3xl" />{" "}
            </div>
          </a>
          {nav && (
            <div
              ref={navRef}
              className="absolute bg-white rounded-2xl top-[130px] px-5"
              style={{ boxShadow: "0px 0px 5px rgba(0,0,0,0.5)" }}
            >
              <div className="my-2 mx-5 text-center">
                <a href="/profile" className="no-underline text-gray-400">
                  Edit Profile
                </a>
              </div>
              <div className="bg-black w-full h-[2px]"></div>
              <div className="my-2 mx-5 text-center">
                <a href="/password" className="no-underline text-gray-400">
                  Change Password
                </a>
              </div>
              <div className="bg-black w-full h-[2px]"></div>
              <div className="my-2 mx-5 text-center">
                <a
                  href=""
                  className="no-underline text-gray-400"
                  onClick={logout}
                >
                  Log out
                </a>
              </div>
            </div>
          )}
        </div>
      </Section>
    </Container>
  );
};

export default HeaderNavAdmin;
