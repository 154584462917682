import React, { useRef } from "react";
import styled, { css } from "styled-components";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useGSAP } from "@gsap/react";

import Fotter from "../../HeaderFotter/Fotter/Fotter.js";
import BlurryCursor from "../../Dependence/Cursor/cursor.jsx";
import FixedNav from "../../HeaderFotter/FixedNav/Fixednav.js";
import HeaderNav from "../../HeaderFotter/Header/Header.js";

const sizes = {
  desktop: 1024,
  tablet: 768,
  phone: 576,
};

const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5;
  font-size: 20px;
  font-weight: 300;
  color: #111;
`;

const Section = styled.section`
  padding: 0 50px;
  padding-bottom: 150px;
  max-width: 1330px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /* Đảm bảo padding không thay đổi kích thước của div */
  box-sizing: border-box;

  ${media.desktop`
    padding: 50px 30px; // ví dụ thay đổi padding trên desktop nhỏ
  `}
  ${media.tablet`
    padding: 20px 20px; // thay đổi padding trên tablet
  `}
  ${media.phone`
    padding: 10px 10px; // thay đổi padding trên điện thoại
    padding-bottom: 100px; // giảm padding bottom trên điện thoại
  `}
`;

const NotFound = () => {
  gsap.registerPlugin(useGSAP);
  const content = useRef();

  gsap.registerPlugin(ScrollTrigger);

  useGSAP(
    () => {
    
    },
    { scope: content }
  );

  return (
    <Container className="content" ref={content}>
      {/* headder */}
      <HeaderNav></HeaderNav>

      <Section>
        <div className="flex justify-center font-bold text-clamp-50">Page not Found 404</div>
      </Section>
      {/* Section 7 */}
      <Section className="info-overview">
        <div>
          <div> Phone: 0973759907 </div>
          <div>
            {" "}
            Address: 15 Nguyen Mong Tuan, Hoa Minh, Lien Chieu, Da Nang{" "}
          </div>
          <div>
            {" "}
            Page:
            <a
              className="no-underline"
              href="https://www.facebook.com/visstudio.co"
            >
              {" "}
              VIS Group
            </a>{" "}
          </div>
          <br />
          <div id="ct-main-icon">
            <img
              alt=""
              src="/Img/icon/amazon.png"
              className="ct-image fade entered lazyloaded"
              style={{ transform: "translate(0px, 0px)", opacity: "1" }}
            />
            <img
              alt=""
              src="/Img/icon/tiktok.png"
              className="ct-image fade entered lazyloaded"
              style={{ transform: "translate(0px, 0px)", opacity: "1" }}
            />
            <img
              alt=""
              src="/Img/icon/etsy.jfif"
              className="ct-image fade entered lazyloaded"
              style={{ transform: "translate(0px, 0px)", opacity: "1" }}
            />
            <img
              alt=""
              src="https://www.pinguinweb.de/wp-content/uploads/2022/09/woocommerce.png"
              className="ct-image fade entered lazyloaded"
              style={{ transform: "translate(0px, 0px)", opacity: "1" }}
            />
            <img
              alt=""
              src="https://www.pinguinweb.de/wp-content/uploads/2022/09/oxy.png"
              className="ct-image fade entered lazyloaded"
              style={{ transform: "translate(0px, 0px)", opacity: "1" }}
            />
          </div>
        </div>
      </Section>

      <Fotter></Fotter>

      <FixedNav />
      <BlurryCursor />
    </Container>
  );
};

export default NotFound;
