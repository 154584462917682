import React, { useRef, useState, useEffect } from 'react';
import { gsap } from 'gsap';

const Slider = ({ slides, height, intervalTime = 3000 }) => {
  const sliderRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const showSlide = (index) => {
    gsap.to(sliderRef.current, {
      x: -index * 100 + '%',
      duration: 0.5,
      ease: 'power2.inOut'
    });
  };

  const nextSlide = () => {
    const newIndex = (currentIndex + 1) % slides.length;
    setCurrentIndex(newIndex);
    showSlide(newIndex);
  };

  const prevSlide = () => {
    const newIndex = (currentIndex - 1 + slides.length) % slides.length;
    setCurrentIndex(newIndex);
    showSlide(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, intervalTime);
    return () => clearInterval(interval);
  }, [currentIndex, intervalTime]);

  return (
    <div className="relative w-full overflow-hidden" style={{ height, boxShadow:"-3px 0px 20px 8px rgba(0, 0, 0, .75), -6px 12px 20px 0px rgba(0, 0, 0, .6), -13px 14px 8px 0px rgba(0, 0, 0, .6), -40px 40px 20px 0 rgba(0, 0, 0, .35)"}}>
      <div className="flex h-full" ref={sliderRef}>
        {slides.map((src, index) => (
          <div className="min-w-full" key={index}>
            <img className="w-full h-full object-cover scale-7" src={src} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </div>
      <button onClick={prevSlide} className='absolute z-10 top-[50%] left-0 hover:bg-[#555]' style={{ transform: "scaleX(-1)", backgroundColor:"#9e9e9e0a" }}>
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#fff">
          <path fillRule="evenodd" clipRule="evenodd" d="M7.00015 0.585938L18.4144 12.0002L7.00015 23.4144L5.58594 22.0002L15.5859 12.0002L5.58594 2.00015L7.00015 0.585938Z"></path>
        </svg>
      </button>
      <button onClick={nextSlide} className='absolute z-10 top-[50%] right-0 bg-color-[#6e736f05] hover:bg-[#555]' style={{ backgroundColor: "#9e9e9e0a" }}>
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#fff">
          <path fillRule="evenodd" clipRule="evenodd" d="M7.00015 0.585938L18.4144 12.0002L7.00015 23.4144L5.58594 22.0002L15.5859 12.0002L5.58594 2.00015L7.00015 0.585938Z"></path>
        </svg>
      </button>
    </div>
  );
};

export default Slider;
