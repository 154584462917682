import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import HeaderNav from '../HeaderFotter/Header/Header.js';
import Fotter from '../HeaderFotter/Fotter/Fotter.js';
import BlurryCursor from '../Dependence/Cursor/cursor.jsx';
import FixedNav from '../HeaderFotter/FixedNav/Fixednav.js';

const sizes = {
    desktop: 1024,
    tablet: 768,
    phone: 576,
};

const media = Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
    return acc;
}, {});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5;
  font-size: 20px;
  font-weight: 300;
  color: #111;
`;

const Section = styled.section`
  padding: 0 50px;
  padding-bottom: 150px;
  max-width: 1330px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /* Đảm bảo padding không thay đổi kích thước của div */
  box-sizing: border-box; 
  
  ${media.desktop`
    padding: 50px 30px; // ví dụ thay đổi padding trên desktop nhỏ
  `}
  ${media.tablet`
    padding: 20px 20px; // thay đổi padding trên tablet
  `}
  ${media.phone`
    padding: 10px 10px; // thay đổi padding trên điện thoại
    padding-bottom: 100px; // giảm padding bottom trên điện thoại
  `}
`;

const JdSupport = () => {
    const [isActive, setIsActive] = useState(false);
    
    return (
        <Container className='content' >

            {/* headder */}
            <HeaderNav/>

            {/* Section 1 */}
            <Section className="about-vis opacity-1" >
                <div className=''>
                    <div className='flex justify-center contacts'>
                        <h1 onMouseOver={() => { setIsActive(true) }} onMouseLeave={() => { setIsActive(false) }} id="headline-4-9"
                            className="uppercase text-clamp-100 m-0 ct-headline ">CUSTOMER SUPPORT (Full time)</h1>
                    </div>

                    <div className='flex justify-center uppercase blend fade z-10 leading-10 tracking-widest text-[33px]'>
                        Join Our Journey of Innovation and Excellence
                    </div>

                    <div>
                        <div id="thanh_den" style={{ width: "100%" }}></div>
                    </div>

                    <div className='flex justify-between gap-5'>
                        <div className='h-auto pl-[12%]'>
                            {/* <div id="thanh_trang" style={{ width: "2px", minWidth: "2px", height: "100%", margin: 0 }}></div> */}
                        </div>
                        <div className='w-full'>
                            <p className='text-justify'><strong> 1. Mô tả công việc: <br /></strong>
                                <div className='pl-10'>
                                    Chăm sóc khách hàng bằng tiếng Anh 100%.<br />
                                    Chăm sóc websites bán hàng của Công ty.<br />
                                    Thực hiện dịch vụ chăm sóc khách hàng, giải đáp các thắc mắc, yêu cầu và tư vấn sản phẩm, xử lý khiếu nại của khách hàng.<br />
                                    Chốt đơn cho khách hàng.<br />
                                    Phối hợp với các bộ phận liên quan để giải quyết về đơn hàng.<br />
                                    Đề xuất, tham gia phân tích và xây dựng kế hoạch Idea cùng Team.<br />
                                    Các công việc khác theo yêu cầu.
                                </div>

                            </p>
                            <p className='text-justify'><strong>2. Yêu cầu cơ bản:<br /></strong>
                                <div className='pl-10'>
                                    Tốt nghiệp cao đẳng trở lên.<br />
                                    Sử dụng tiếng Anh thành thạo đặc biệt kỹ năng viết và đọc hiểu.<br />
                                    Sử dụng thành thạo tin học văn phòng.<br />
                                    Khả năng giao tiếp, đàm phán tốt.<br />
                                    Tinh thần trách nhiệm, cầu tiến.<br />
                                    Am hiểu về thương mại điện tử là một lợi thế.<br />

                                </div>
                            </p>
                            <p className='text-justify'><strong>3. Quyền lợi: <br /></strong>
                                <div className='pl-10'>
                                    Thu nhập thỏa thuận<br />
                                    Thưởng theo chính sách của Công ty.<br />
                                    Được hưởng đầy đủ các chính sách và các phúc lợi khác.<br />
                                    Môi trường làm việc năng động, thân thiện, chuyên nghiệp.<br />
                                </div>
                            </p>
                            <p>🙌 Hãy ứng tuyển ngay để bắt đầu một hành trình sự nghiệp thú vị với đại gia đình VIS chúng tôi !</p>
                        </div>
                    </div>

                    <div>
                        <div className='mt-48' id="thanh_trang" style={{ minHeight: "1px", height: "1px", width: "100%" }}></div>
                    </div>

                    <div className='flex justify-between gap-5'>
                        {/* <div className='h-auto pl-20'>
                            <div id="thanh_den" style={{ width: "2px", minWidth: "2px", height: "100%", margin: 0 }}></div>
                        </div> */}
                        <div className='w-full '>
                            <p className='text-justify'><strong>Thông tin liên hệ VIS GROUP:<br /></strong>
                                <div className='pl-10'>
                                    - Địa chỉ làm việc: 15 Nguyễn Mộng Tuân, Liên Chiểu, Đà Nẵng<br />
                                    - Thời gian làm việc:<br />
                                    <div className='pl-10'>
                                        + Full-time làm việc các ngày từ thứ 2 – thứ 7 (thứ 7 làm cách tuần).<br />
                                        + Sáng: 8h00 – 12h00 Chiều: 13h30 – 17h30<br />
                                    </div>
                                    <p></p>
                                    Ứng viên vui lòng gửi CV về địa chỉ email: recruitment@visgroup.co<br />
                                    Email title <span className='text-red-400'>[CUSTOMER SUPPORT – HỌ TÊN – SĐT]</span>
                                </div>

                            </p>
                        </div>
                    </div>
                </div>
            </Section>

            <Fotter></Fotter>

            <FixedNav />
            <BlurryCursor isActive={isActive} />
        </Container>
    );
};

export default JdSupport;