import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { useForm } from "react-hook-form";

import HeaderNavAdmin from "../../HeaderFotter/Header/AdminHeader.js";
import Fotter from "../../HeaderFotter/Fotter/Fotter.js";
import BlurryCursor from "../../Dependence/Cursor/cursor.jsx";
import FixedNav from "../../HeaderFotter/FixedNav/Fixednav.js";
import axiosInstance from "../../../axiosInstance.js";
import { ColorRing } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const sizes = {
  desktop: 1024,
  tablet: 768,
  phone: 576,
};

const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5;
  font-size: 20px;
  font-weight: 300;
  color: #111;
`;

const Section = styled.section`
  padding: 0 50px;
  padding-bottom: 100px;
  max-width: 1330px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /* Đảm bảo padding không thay đổi kích thước của div */
  box-sizing: border-box;

  ${media.desktop`
    padding: 50px 30px; // ví dụ thay đổi padding trên desktop nhỏ
  `}
  ${media.tablet`
    padding: 20px 20px; // thay đổi padding trên tablet
  `}
  ${media.phone`
    padding: 10px 10px; // thay đổi padding trên điện thoại
    padding-bottom: 100px; // giảm padding bottom trên điện thoại
  `}
`;

const ChangePassword = ({ token }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({});
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  const newPassword = watch("new_password");
  const confirmPassword = watch("confirm_password");

  const validatePasswords = () => {
    const passwordRegex = /(?=.*[!@#$%^&*])/; // Regex để kiểm tra ký tự đặc biệt
    return (
      newPassword === confirmPassword &&
      newPassword.length >= 8 &&
      passwordRegex.test(newPassword)
    );
  };

  const onSubmit = async (data) => {
    data["username"] = user.username;
    setIsLoading(true);

    try {
      const response = await axiosInstance.patch("/auth/change-password", data);

      // console.log(response);
      if (response.status === 200) {
        // Thong bao thanh cong o day:
        toast.success(response.data.message);
        localStorage.removeItem("token");
        setTimeout(() => {
          navigate("/login");
        }, 500);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      // cái log dưới để log resopne
      // console.log(error.response);
      console.error("Error uploading data:", error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false); // Kết thúc loading
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchProtectedData = async () => {
      try {
        const response = await axiosInstance.get("/auth/protected", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data.user);
      } catch (error) {
        localStorage.removeItem("token");
        navigate("/login");
        // window.location.href = '/login'; // Redirect to login page
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      fetchProtectedData();
    }
  }, [token]);

  return (
    <Container className="content">
      {/* headder */}
      <HeaderNavAdmin user={user} />

      {/* Section 1 */}
      <Section
        className="our-teams opacity-1 bg-mask"
        // style={{ paddingTop: "100px" }}
      >
        <p className="text-center uppercase font-bold text-clamp-50">
          Change Password
        </p>
        <div>
          <div className="pl-5 flex justify-center">
            {/* <p className="text-center uppercase font-bold text-clamp-50">
              Bài 1
            </p> */}
            <div
              className="px-10 py-5 w-5/12 rounded-2xl"
              style={{ boxShadow: "0px 0px 5px rgba(0,0,0,0.5)" }}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container ">
                  <div className="form-contacts">
                    <div className="flex ">
                      <span className="w-8/12 flex items-center font-bold">
                        Current Password*
                      </span>
                      <input
                        type="password"
                        {...register("current_password", {
                          required: "Current Password is require!",
                        })}
                      />
                    </div>
                    {errors.current_password && (
                      <div style={{ color: "red" }}>
                        {errors.current_password.message}
                      </div>
                    )}
                  </div>
                  <div className="form-contacts">
                    <div className="flex">
                      <span className="w-8/12 flex items-center font-bold">
                        New Password*
                      </span>
                      <input
                        className="w-6/12"
                        type="password"
                        {...register("new_password", {
                          required: "New Password is require!",
                          minLength: {
                            value: 8,
                            message:
                              "Password must be at least 8 characters long",
                          },
                          pattern: {
                            value: /(?=.*[!@#$%^&*])/,
                            message:
                              "Password must contain at least one special character",
                          },
                        })}
                      />
                    </div>
                    {errors.new_password && (
                      <div style={{ color: "red" }}>
                        {errors.new_password.message}
                      </div>
                    )}
                  </div>

                  <div className="form-contacts">
                    <div className="flex">
                      <span className="w-8/12 flex items-center font-bold">
                        Confirm Password*
                      </span>
                      <input
                        type="password"
                        {...register("confirm_password", {
                          required: "Confirm Password is require!",
                          validate: validatePasswords,
                        })}
                      />
                    </div>
                    {confirmPassword && !validatePasswords() && (
                      <div style={{ color: "red" }}>
                        Passwords do not match or do not meet the requirements
                      </div>
                    )}
                    {errors.confirm_password && (
                      <div style={{ color: "red" }}>
                        {errors.confirm_password.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex justify-center pt-10">
                  <button
                    type="submit"
                    className="px-12 py-4 text-xl rounded-xl hover:bg-[#45a049]"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Section>

      <Fotter></Fotter>

      <FixedNav />
      <BlurryCursor />

      <ToastContainer />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgb(75 81 99 / 80%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <ColorRing
            height={70}
            width={70}
            color="#4fa94d"
            ariaLabel="loading-indicator"
          />
        </div>
      )}
    </Container>
  );
};

export default ChangePassword;
